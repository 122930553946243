import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Facturacion from './Facturacion/Facturacion'
import Consulta from './Consulta/Consulta'
import Header from './Header/Header'
import './normalize.css'
import './main.css'

const Routes = () => (
  <React.Fragment>
    <Switch>
      <Route exact path='/' component={ Facturacion } />
      <Route exact path='/consulta' component={ Consulta } />
    </Switch>
  </React.Fragment>
)

class App extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="container">
          <Routes />
        </div>
      </div>
    )
  }
}

export default App
