import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => (
  <div className='nav-bar'>
    <div className="nav">
      <div className="logo"><img src="https://mint.nyc3.digitaloceanspaces.com/storage-store/public/assets/logo_text.png" alt="facturacion"/ ></div>
      <div className="logo-min"><img src="https://mint.nyc3.digitaloceanspaces.com/storage-store/public/assets/logo_c.png" alt="facturacion"/ ></div>
      <Link to="/" className="nav-item">Generar Factura</Link>
      <Link to="/consulta"className="nav-item">Consultar Facturas</Link>
    </div>
  </div>
)

export default Header