import React, { Component } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faFileDownload } from '@fortawesome/free-solid-svg-icons'

class Facturacion extends Component {

  state = {
    rfc: '',
    nombre: '',
    email: '',
    folio: '',
    domicilioFiscal: '',
    regimenFiscal: '',
    desRegimenFiscal: '',
    orden: {},
    consultaOrden: false,
    formErrors: {
      rfc: '',
      nombre: '',
      email: '',
      folio: '',
      domicilioFiscal: '',
      regimenFiscal: ''
    },
    rfcValid: false,
    nombreValid: false,
    emailValid: false,
    folioValid: false,
    domicilioFiscalValid: false,
    regimenFiscalValid: false,
    formValid: false,
    facturando: false,
    facturaResponse: '',
    facturaFiles: []
  }

  handleRFC = (event) => {
    const { value } = event.target
    if (value.toUpperCase() === 'XAXX010101000') return
    if (value.length < 12 || value.length > 13) return
    axios.post('/api/factura/rfc', { rfc: this.state.rfc }).then(({ data }) => {
      if (!data) return
      const { email } = data
      this.setState({ email, emailValid: true })
    })
  }

  validateField = (fieldName, value) => {
    let { formErrors, rfcValid, nombreValid, emailValid, folioValid, domicilioFiscalValid, regimenFiscalValid } = this.state
    switch (fieldName) {
      case 'rfc':
        rfcValid = (11 < value.length && value.length < 14 && value.toUpperCase() !== 'XAXX010101000')
        formErrors.rfc = rfcValid ? '': 'Errores en RFC'
        break;
      case 'nombre':
        nombreValid = value.length > 0
        formErrors.nombre = nombreValid ? '': 'Errores en Razon Social'
        break;
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        formErrors.email = emailValid ? '': 'Errores en Correo electrónico'
        break;
      case 'folio':
        folioValid = value.length > 0
        formErrors.folio = folioValid ? '': 'Errores en No. Ticket'
        break;
      case 'domicilioFiscal':
        domicilioFiscalValid = value.length === 5
        formErrors.domicilioFiscal = domicilioFiscalValid ? '': 'Errores en Domicilio Fiscal'
        break;
      case 'regimenFiscal':
        regimenFiscalValid = parseInt(value) > 0
        formErrors.regimenFiscal = regimenFiscalValid ? '': 'Errores en Régimen Fiscal'
        break;
      default:
        break;
    }
    this.setState({ formErrors, rfcValid, nombreValid, emailValid, folioValid, domicilioFiscalValid, regimenFiscalValid }, this.validateForm);
  }

  validateForm = () => this.setState({formValid: this.state.rfcValid && this.state.nombreValid && this.state.emailValid && this.state.folioValid && this.state.domicilioFiscalValid && this.state.regimenFiscalValid });

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }

  handleCP = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    
    this.setState({
      facturaResponse: '',
      facturaFiles: [],
      regimenFiscal: this.state.regimenFiscal.split("-")[0].trim(),
      desRegimenFiscal: this.state.regimenFiscal.split("-")[1].trim()
    })
    axios.post('/api/factura/orden', { folio: this.state.folio })
      .then(({ data }) => this.setState({ orden: data, consultaOrden: true }, () => {
       this.handleFacturar()
      }));
  }

  handleFacturar = () => {
    const { orden } = this.state
    if(Object.values(orden).length > 0 && orden.factura === 0) {
      this.setState({ facturando: true, facturaResponse: '', facturaFiles: [] })
      axios.post('/api/factura', this.state).then(({ data }) => {
        console.log(data)
        this.setState({ facturando: false })
        if (data.status === 'ER') {
          this.setState({ facturaResponse: data.consulta.substring(3) })
        }

        if (data.status === 'OK') {
          this.setState({ facturaFiles: data.tmpFiles })
          axios.post('/api/factura/cliente', this.state)
          axios.post('/api/factura/updatexmlpdf', { orden: this.state.orden, files: data })
        }
      })
    }
  }

  render() {
    const { orden, consultaOrden, facturando, facturaResponse, facturaFiles } = this.state;
    return(
      <div className="container-box">
        <h3>Generar Factura Electrónica</h3>
        <div className="factura-container">
          <form className="factura-form" onSubmit={this.handleSubmit}>
            <div className="form-input-group">
              <label>No. Ticket</label>
              <input type="input" name="folio" value={this.state.folio} onChange={this.handleChange} />
            </div>
            <div className="form-input-group">
              <label>RFC</label>
              <input type="input" name="rfc" value={this.state.rfc} onChange={this.handleChange} onBlur={this.handleRFC} className="uppertext" />
            </div>
            <div className="form-input-group">
              <label>Razón Social</label>
              <input type="input" name="nombre" value={this.state.nombre} onChange={this.handleChange} className="uppertext" />
            </div>
            <span className="razon-social">
              Razón Social sin Regimen de Capital <br/>
              Ejemplo: "SA de CV", "S de RL de CV", "SC", etc.
            </span>
            <div className="form-input-group">
              <label>Código Postal</label>
              <input type="input" maxLength={5} name="domicilioFiscal" value={this.state.domicilioFiscal} onChange={this.handleChange} onKeyPress={this.handleCP} className="uppertext" />
            </div>
            <div className="form-input-group">
              <label>Régimen Fiscal</label>
              <select name="regimenFiscal" value={this.state.regimenFiscal} onChange={this.handleChange}>
                <option value={'0 - Régimen Fiscal'}>-- Régimen Fiscal --</option>
                <option value={'601 - General de Ley Personas Morales'}>601 - General de Ley Personas Morales</option>
                <option value={'603 - Personas Morales con Fines no Lucrativos'}>603 - Personas Morales con Fines no Lucrativos</option>
                <option value={'605 - Sueldos y Salarios e Ingresos Asimilados a Salarios'}>605 - Sueldo y Salarios e Ingresos Asimilados a Salarios</option>
                <option value={'606 - Arrendamiento'}>606 - Arrendamiento</option>
                <option value={'607 - Régimen de Enajenación o Adquisición de Bienes'}>607 - Régimen de Enajenación o Adquisición de Bienes</option>
                <option value={'608 - Demás ingresos'}>608 - Demás ingresos</option>
                <option value={'610 - Residentes en el Extranjero sin Establecimiento Permanente en México'}>610 - Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                <option value={'611 - Ingresos por Dividendos (socios y accionistas)'}>611 - Ingresos por Dividendos (socios y accionistas) </option>
                <option value={'612 - Personas Físicas con Actividades Empresariales y Profesionales'}>612 - Personas Físicas con Actividades Empresariales y Profesionales</option>
                <option value={'614 - Ingresos por intereses'}>614 - Ingresos por intereses</option>
                <option value={'615 - Régimen de los ingresos por obtención de premios'}>615 - Régimen de los ingresos por obtención de premios</option>
                <option value={'616 - Sin obligaciones fiscales'}>616 - Sin obligaciones fiscales</option>
                <option value={'620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos'}>620 - Sociedades Cooperativas de Producción que optan por Diferir sus Ingresos</option>
                <option value={'621 - Incorporación Fiscal'}>621 - Incorporación Fiscal</option>
                <option value={'622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras'}>622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                <option value={'623 - Opcional para Grupos de Sociedades'}>623 - Opcional para Grupos de Sociedades</option>
                <option value={'624 - Coordinados'}>624 - Coordinados</option>
                <option value={'625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas'}>625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
                <option value={'626 - Régimen Simplificado de Confianza'}>626 - Régimen Simplificado de Confianza</option>
              </select>
            </div>
            <div className="form-input-group">
              <label>Correo electrónico</label>
              <input type="email" name="email" value={this.state.email} onChange={this.handleChange} className="lowertext" />
            </div>
            <button type="submit" disabled={!this.state.formValid || this.state.facturando}>Facturar</button>
          </form>
          <div className="factura-consult">
            { (consultaOrden && Object.values(orden).length === 0) &&
              <div className="alert alert-warning">Ticket no existe.</div> }

            { orden.factura === 1 &&
              <div className="alert alert-warning">Ticket ya facturado.</div> }

            { facturando && <div className="alert center">Facturando &nbsp;<FontAwesomeIcon icon={faSpinner} pulse /></div> }

            { facturaResponse.length > 0 && <div className="alert alert-warning">{ facturaResponse.substr(0, 60) }</div> }

            { facturaFiles.length > 0 &&
              <React.Fragment>
                <div className="alert alert-success">Factura Generada</div>
                <div className="factura">
                  { `Factura electrónica enviada al correo: ${this.state.email}` }
                </div>
                <div className="factura">
                  <a href={facturaFiles[0]}><FontAwesomeIcon icon={faFileDownload} />&nbsp; XML</a>
                  <a href={facturaFiles[1]}><FontAwesomeIcon icon={faFileDownload} />&nbsp; PDF</a>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Facturacion
