import React, { Component } from 'react'
import axios from 'axios'
import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faFilePdf, faEnvelope } from '@fortawesome/free-solid-svg-icons'

class Consulta extends Component {

  state = {
    rfc: '',
    email: '',
    facturas: [],
    formErrors: {
      rfc: '',
      email: '',
    },
    rfcValid: false,
    emailValid: false,
    formValid: false,
    formValidMail: false,
    emailResponse: '',
    emailSendResponse: ''
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }

  validateField = (fieldName, value) => {
    let { formErrors, rfcValid } = this.state
    switch (fieldName) {
      case 'rfc':
        rfcValid = (11 < value.length && value.length < 14)
        formErrors.rfc = rfcValid ? '': 'Errores en RFC'
        break;
      default:
        break;
    }

    this.setState({ formErrors, rfcValid }, this.validateForm);
  }

  validateForm = () => this.setState({formValid: this.state.rfcValid});


  handleSubmit = (event) => {
    event.preventDefault()
    axios.post('/api/consulta', this.state).then(({ data }) => this.setState({ facturas: data }))

    axios.post('/api/factura/rfc', { rfc: this.state.rfc }).then(({ data }) => {
      if (!data) return
      const { email } = data
      this.setState({ email, emailValid: true })
    })
  }

  // Mail 
  handleChangeMail = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      this.validateFieldMail(name, value)
    })
  }

  validateFieldMail = (fieldName, value) => {
    let { formErrors, emailValid } = this.state
    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        formErrors.email = emailValid ? '': 'Errores en Correo electrónico'
        break;
      default:
        break;
    }

    this.setState({ formErrors, emailValid }, this.validateFormMail);
  }

  validateFormMail = () => this.setState({formValidMail: this.state.emailValid});

  handleSubmitMail = (event) => {
    event.preventDefault()
    axios.post('/api/factura/cliente-update-mail', this.state)
      .then(response => this.setState({emailResponse: response.data.message}))
    setTimeout(() => this.setState({ emailResponse: '' }), 3000)
  }

  handleMail = (folio) => {
    this.setState({emailSendResponse: 'Enviando factura'})
    axios.post('/api/consulta/mail', { folio  })
      .then(response => this.setState({emailSendResponse: response.data.message}))
    setTimeout(() => this.setState({ emailSendResponse: '' }), 10000)
  }

  render(){
    const { facturas, emailResponse, emailSendResponse } = this.state
    return(
      <div className="container-box">
        <h3>Consultar Facturas</h3>
        <div className="cliente">
          <form className="consulta-form" onSubmit={this.handleSubmit}>
            <label>RFC</label>
            <input type="input" name="rfc" onChange={this.handleChange} className="uppertext" />
            <button type="submit" disabled={!this.state.formValid}>Consultar</button>
          </form>
          <div className="mail-form">
            <form className="consulta-form" onSubmit={this.handleSubmitMail}>
              <label>Correo electrónico</label>
              <input type="email" name="email" value={this.state.email} onChange={this.handleChangeMail} className="lowertext" />
              <button type="submit" disabled={!this.state.formValidMail}>Actualizar</button>
            </form>
            <span>{ emailResponse }</span>
          </div>
        </div>
        <div className="mail-send-msg">
          <p>{ emailSendResponse }</p>
        </div>
        { facturas.length > 0 && 
          <div>  
            <table className="facturas">
              <thead>
                <tr>
                  <th>Serie y Folio</th>
                  <th className="hide-xs">Fecha Emisón</th>
                  <th className="hide-xs">Folio Fiscal</th>
                  <th>Monto</th>
                  <th>XML</th>
                  <th>PDF</th>
                  <th>Reenviar</th>
                </tr>
              </thead>
              <tbody>
                {facturas.map((item, key) => 
                  (<tr key={ key}>
                    <td>{ item.serie } - { item.clavedoc }</td>
                    <td className="hide-xs"><Moment format="YYYY-MM-DD">{ item.fecha }</Moment>T{ item.hora }</td>
                    <td className="hide-xs">{ item.uuid }</td>
                    <td>$ { item.total }</td>
                    <td>{ (item.url_xml.length > 0) ? <a href={ item.url_xml }><FontAwesomeIcon icon={faFileDownload} /></a> : <span></span> }</td>
                    <td>{ (item.url_pdf.length > 0) ? <a href={ item.url_pdf }><FontAwesomeIcon icon={faFilePdf} /></a> : <span></span> }</td>
                    <td>{ (item.url_pdf.length > 0) ? <span className="sendmail" onClick={() => this.handleMail(item.uuid)}><FontAwesomeIcon icon={faEnvelope} /></span> : <span></span> }</td>
                  </tr>)
                )}
              </tbody>
            </table>
          </div>
        }
      </div>
    )
  }
}
export default Consulta